import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';
import axios from 'axios';
import {Redirect} from '@reach/router';
import {useQuery} from '@apollo/client';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import SubHeader from './components/Header';
import StepHeader from './components/StepHeader';
import SubscriptionPackages from './components/SubscriptionPackages';
import Container from './components/common/index';
import {useTap} from './hooks/useTap';
import {
  PaymentWrapper,
  DescriptionWrapper,
  ButtonWrapper,
  Button,
  CouponWrapper,
  SubmitWrapper,
  Loader,
  Line,
} from './styles';
import ChosenPlan from './components/ChosenPlan';
import PaymentMethods from './components/PaymentMethods';
import PayPalButton from './components/PaymentMethods/Paypal/Button';
import SubscriptionDescription from './components/SubscriptionDescription';
import SUBSCRIPTION from './graphql/subscription.graphql';
import RadioBox from './components/SubscriptionPackages/RadioBox';
// import AmazonButton from './components/PaymentMethods/Credit/Button';

const isBrowser = typeof window !== 'undefined';
const defaultPlanName = () => {
  const plan = new URLSearchParams(window.location.search).get('plan');
  switch (plan) {
    case 'MONTHLY':
    case 'YEARLY':
      return plan;
    case 'FULL_YEARLY':
    default:
      return 'FULL_YEARLY';
  }
};

export default () => {
  const {data, loading} = useQuery(SUBSCRIPTION);
  const authAndUser = useSelector((state) => state);
  const isLoaded = typeof window.Tapjsli === 'function';
  const {card, isCardError, handleOnLoad, tap} = useTap();
  const [selectedPlan, setSelectedPlan] = useState(() => defaultPlanName());
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [currency, setCurrency] = useState('USD');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('TAP');
  // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('AMAZON');
  const [paymentInfo, setPaymentInfo] = useState(null);

  const usedPaymentMethod = data?.recurrentPayment?.paymentMethod;
  const isActiveSubscription = data?.recurrentPayment?.status === 'ACTIVE';
  const isCanceledSubscription = data?.recurrentPayment?.status === 'CANCELED';

  // Start of TAP CODE
  const apiKey = authAndUser?.auth?.key;

  const isSubmitButtonDisabled =
    !paymentInfo || paymentInfo === 'newPaymentInfo'
      ? submitStatus === 'loading' || isCardError
      : submitStatus === 'loading' || !paymentInfo;

  const createSubscription = async (headers) => {
    try {
      const createSub = await axios.post(
        `${process.env.GATSBY_API2}api/v1/billing/subscription/create-subscription/?apikey=${apiKey}`,
        {
          plan_name: selectedPlan,
          payment_method: 'TAP',
        },
        headers,
      );

      const activateSub = axios.post(
        `${process.env.GATSBY_API2}api/v1/billing/payment/${createSub?.data?.id}/pay-tap-subscription/?apikey=${apiKey}`,
        headers,
      );

      navigate('/subscription/message', {
        state: {
          fromLink: window.history.state?.fromLink,
        },
      });
    } catch (error) {
      notification({id: 'server_error_try_again'});
      setSubmitStatus('idle');
    }
  };

  const handleSubmit = () => {
    if (isBrowser) {
      setSubmitStatus('loading');
      const headers = {
        'Content-Type': 'application/json',
      };
      if (paymentInfo !== 'oldPaymentInfo') {
        tap.createToken(card).then(async (result) => {
          if (result.error) {
            notification({message: result.error});
          } else {
            try {
              const res = await axios.post(
                `${process.env.GATSBY_API2}api/v1/billing/payment/create-tap-information/?apikey=${apiKey}`,
                {
                  user_id: authAndUser?.user?.profile?.data?.id,
                  tap_card_token: result.id,
                },
                headers,
              );
              createSubscription(headers);
            } catch (error) {
              notification({id: 'server_error_try_again'});
            }
          }
        });
      } else {
        createSubscription(headers);
      }
    }
  };
  useEffect(() => {
    if (submitStatus === 'idle' && isLoaded) {
      handleOnLoad();
    }
  }, [submitStatus, isLoaded]);
  // End of TAP CODE

  useEffect(() => {
    const planName = data?.recurrentPayment?.subscriptionPlan?.planName;
    if (planName) {
      setSelectedPlan(planName);
    }
  }, [data]);

  // Changing payment method is not yet ready
  if (isActiveSubscription) {
    return <Redirect noThrow to="/tracks" />;
  }

  let topSection;
  if (loading) {
    topSection = <LoadingIndicator />;

  } else if (!isActiveSubscription) {
    topSection = (
      <>
        <StepHeader step="1" message_id="choose_new_subscription_pack" />

        <SubscriptionPackages
          radioState={[selectedPlan, setSelectedPlan]}
          currency={currency}
          isActiveSubscription={isActiveSubscription}
        />
      </>
    );
  } else {
    topSection = null;
  }

  return (
    <>
      <SubHeader />

      {topSection}

      <StepHeader
        step={!isActiveSubscription ? '2' : '1'}
        message_id="choose_payment_method"
      />
      {usedPaymentMethod && isActiveSubscription && (
        <Container className="flex flex-column flex-row-ns items-center pt4">
          <RadioBox
            header="طريقة الدفع الحالية"
            usedPaymentMethod={usedPaymentMethod}
            methodName="oldPaymentInfo"
            setPaymentInfo={setPaymentInfo}
            paymentInfo={paymentInfo}
            oldPaymentMethod
          />
          <RadioBox
            header="طريقة دفع جديدة"
            subHeader="أضف طريقة مختلفة لشراء خطتك"
            methodName="newPaymentInfo"
            setPaymentInfo={setPaymentInfo}
            paymentInfo={paymentInfo}
            oldPaymentMethod
          />
        </Container>
      )}
      <Container>
        {(!isActiveSubscription || paymentInfo === 'newPaymentInfo') && (
          <PaymentWrapper>
            <PaymentMethods
              setCurrency={setCurrency}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          </PaymentWrapper>
        )}
        <Line />
        <DescriptionWrapper>
          <ChosenPlan plan={selectedPlan} currency={currency} />
          <SubscriptionDescription />
        </DescriptionWrapper>
        <SubmitWrapper>
          <CouponWrapper>
            {/* <h4>رمز الكوبون</h4>
                <div>
                  <input placeholder="أدخل رمز الكوبون (اختياري)" />
                  <ButtonCoupon>طبق</ButtonCoupon>
                </div> */}
          </CouponWrapper>
          <ButtonWrapper>
            {selectedPaymentMethod === 'PAYPAL' ||
            (paymentInfo === 'oldPaymentInfo' &&
              usedPaymentMethod.name === 'PAYPAL') ? (
              <PayPalButton
                selectedPlan={selectedPlan}
                statusState={[submitStatus, setSubmitStatus]}
              />
            ) : (
              // Start of TAP CODE
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitButtonDisabled}
              >
                {submitStatus === 'loading' ? (
                  <Loader />
                ) : (
                  <span>{`${
                    isActiveSubscription || isCanceledSubscription
                      ? 'إنهاء العملية'
                      : 'ابدأ فترتك التجرييبة'
                  }`}</span>
                )}
              </Button>
              // End of TAP CODE
              // <AmazonButton
              //   selectedPlan={selectedPlan}
              //   statusState={[submitStatus, setSubmitStatus]}
              //   paymentInfo={paymentInfo}
              //   isActiveSubscription={isActiveSubscription}
              //   isCanceledSubscription={isCanceledSubscription}
              // />
            )}
          </ButtonWrapper>
        </SubmitWrapper>
      </Container>
    </>
  );
};
